const SELECTOR_FILIAL_LINK = '.filial-link';

class BuchhandlungGlobalHeader extends HTMLElement {

  constructor() {
    super();
    this.favoritenFiliale = null;
    this.overlay = null;
    this.overlayLoadUrl = null;
    this.cookieName = null;
    this.headerLinkInitialized = false;
    this.overlayInitialized = false;
  }

  connectedCallback() {
    this.setElements();
    this.readDataAttributes();
    this.readCookieValue();
    this.initHeaderLink();
    this.initOverlay();
    this.bindUpdateEvent();
  }

  bindUpdateEvent() {
    window.addEventListener('kundenfiliale-gesetzt', (event) => this.onFavoritenFilialeUpdate());
  }

  onFavoritenFilialeUpdate() {
    this.readCookieValue();

    if (!this.headerLinkInitialized) {
      this.initHeaderLink();
    }

    if (!this.overlayInitialized) {
      this.initOverlay();
    } else {
      this.updateOverlay();
    }
  }

  setElements() {
    this.filialLink = this.querySelector(SELECTOR_FILIAL_LINK);
  }

  readCookieValue() {
    this.favoritenFiliale = this.getFilialCookie();
  }

  getFilialCookie() {
    return this.getCookie(this.cookieName);
  }

  initHeaderLink() {
    if (!this.favoritenFiliale) {
      return;
    }
    this.filialLink.setAttribute('status', 'favorit');
    this.filialLink.setAttribute('interaction', 'filiale-ausklappen');
    this.headerLinkInitialized = true;
  }

  uninitHeaderLink() {
    this.filialLink.setAttribute('status', '');
    this.filialLink.setAttribute('interaction', 'link-location');
    this.headerLinkInitialized = false;
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  }

  async initOverlay() {
    if (!this.favoritenFiliale) {
      return;
    }
    const success = await this.fetchOverlay();
    if (success) {
      this.bindOpenEvent();
      this.bindCloseEvent();
      this.overlayInitialized = true;
    }
  }

  async updateOverlay() {
    if (!this.favoritenFiliale) {
      return;
    }
    const success = await this.fetchOverlay();
    if (success) {
      this.bindCloseEvent();
    }
  }

  async fetchOverlay() {
    const response = await fetch(this.overlayLoadUrl.replace('{sapId}', this.favoritenFiliale));
    let success = false
    if (response.status === 200) {
      try {
        const html = await response.text();
        this.insertIntoDom(html);
        success = true
      } catch (error) {
        console.log(error);
        success = false;
      }
    }
    if (!success) {
      this.uninitHeaderLink();
    }
    return success;
  }

  insertIntoDom(html) {
    const overlaySnippet = this.querySelector('filialservice-snippet');
    if (overlaySnippet) {
      overlaySnippet.remove();
    }

    this.insertAdjacentHTML('beforeend', html);
    this.overlay = this.querySelector('buchhandlung-header-overlay .overlay-header');
  }

  bindOpenEvent() {
    this.filialLink.addEventListener('click', (event) => this.openOverlayHeader(event));
  }

  bindCloseEvent() {
    const overlayCloseButton = this.querySelector("[interaction='overlay-schliessen']");
    overlayCloseButton.addEventListener('click', () => this.closeOverlayHeader())
  }

  openOverlayHeader(event) {
    event.preventDefault();
    this.overlay.showModal();
  }

  closeOverlayHeader() {
    this.overlay.setAttribute('closing','');
    this.overlay.close();
    this.overlay.removeAttribute('closing');
  }

  readDataAttributes() {
    const currentScript = document.querySelector('script[data-filialcookie]');

    this.cookieName = currentScript.dataset.filialcookie;
    this.overlayLoadUrl = currentScript.dataset.overlayUrl;
  }
}

if (!customElements.get("header-prime-filiale")) {
  customElements.define("header-prime-filiale", BuchhandlungGlobalHeader);
}
